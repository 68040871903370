import React from "react";
import { useDispatch, useSelector } from "react-redux";

import type { AppDispatch, RootState } from "./store"; // Adjust the import path to your store configuration
import { useNavigate } from "react-router-dom";
import { error } from "console";

const source = process.env.REACT_APP_API_SERVER;

const BusinessWelcome: React.FC = () => {
  const area = useSelector((state: RootState) => state.reg.area);
  const district = useSelector((state: RootState) => state.reg.district);
  const address = useSelector((state: RootState) => state.reg.address);
  const login_name = useSelector((state: RootState) => state.reg.login_name);
  const contact_no = useSelector((state: RootState) => state.reg.contact_no);
  const shop_name = useSelector((state: RootState) => state.reg.shop_name);
  const password = useSelector((state: RootState) => state.reg.login_password);

  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      const response = await fetch(`${source}/businessRegister/info`, {
        method: "POST", // Specify the method
        headers: {
          "Content-Type": "application/json", // Specify content type header
        },
        body: JSON.stringify({
          login_name: login_name,
          shop_name: shop_name,
          login_password: password,
          contact_no: contact_no,
          area: area,
          district: district,
          address: address,
        }),
      });

      if (!response.ok) {
        navigate("/businessRegister");
        // Alert the error message
      } else {
        // Handle successful response
        navigate("/businessLogin"); // Redirect to '/mainpage'
        alert("註冊成功，重新登入！");
      }

      const data = await response.json();

      console.log(data);

      if (data.error) {
        alert(data.error); // Alert the error message
      } else {
        // Handle successful response
      }
    } catch (error) {
      // console.error('Fetch error:', error);
    }
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h1 style={{ fontSize: "24px", paddingBottom: "3rem" }}>
          確認輸入資料
        </h1>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h1 style={{ fontSize: "24px", paddingBottom: "3rem" }}>
          您的註冊資料
        </h1>
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ fontSize: "24px", paddingBottom: "1rem" }}>
            <h3>登入名稱：{login_name}</h3>
            <h3>店舖名稱：{shop_name}</h3>
            <h3>登入密碼：{password}</h3>
            <h3>聯絡電話：{contact_no}</h3>
            <h3>地域：{area}</h3>
            <h3>分區：{district}</h3>
            <h3>地址：{address}</h3>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h1 style={{ fontSize: "24px", paddingBottom: "1rem" }}>
          按註冊進入商家版面設置餐單！
        </h1>
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            type="submit"
            className="  w-64 rounded-md bg-green-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-8"
            style={{
              borderColor: "transparent",
            }}
            onClick={handleClick}
          >
            註冊
          </button>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            type="button"
            className="  w-64 rounded-md bg-green-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-8"
            style={{
              borderColor: "transparent",
            }}
            onClick={() => navigate(-1)}
          >
            上一步
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessWelcome;
